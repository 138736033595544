import React from 'react';
import { Container } from 'react-bootstrap';
import './ourprocess.css';
import Line from "../Assets/Line 9.svg";
import p1 from '../Assets/p1.svg';
import p2 from '../Assets/p2.svg';
import p3 from '../Assets/p3.svg';
import p4 from '../Assets/p4.svg';
import p5 from '../Assets/p5.svg';
import p6 from '../Assets/p6.svg';
import p7 from '../Assets/p7.svg';
import Line6 from '../Assets/Line 6.svg';
import Line2 from '../Assets/Line 2.svg';
import Line3 from '../Assets/Line 3.svg';
import Line4 from '../Assets/Line 4.svg';
import Line5 from '../Assets/Line 5.svg';

function Ourprocess() {
  return (
    <section className='ourprocess-bg py-5'>
      <Container data-aos="fade-up" data-aos-duration='800'>
     
        <div className="sec-title text-center mb-5">
            <div className="line d-flex justify-content-center align-items-center mb-3">
            <img src={Line} alt="" className='me-2 lineimg'/><h5 className='sec-titleb'>What we can do for you</h5>
           
            </div>
            <h2 className='title-sec'>Services we can help you with</h2>

            <div className="row  ">
                <div className="col-md-6 align-items-center py-5">
                    <div className="col-md-9">
                    <div className="cardp bg-white text-left">
                <div className="p-img-title d-flex align-items-center mb-3">
                <img src={p1} width={60} alt="" className='img-fluid' />
            <h4 className="title-process ms-3">Discovery and Planning</h4>
                </div>
            
            <p>Gather client requirements, perform a competitive analysis, conduct research, and create a project plan.</p>
            </div>
                    </div>
                        
             
                <img src={Line6} alt="" className='img-fuild lines' />
                <img src={Line4} alt="" className='img-fuild mobilelines' />
              
            
                    </div>

                <div className="col-md-6 align-items-center py-5">
                    <div className="col-md-9">
                    <div className="cardp bg-white text-left">
                <div className="p-img-title d-flex align-items-center mb-3">
                <img src={p2} width={60} alt="" className='img-fluid' />
            <h4 className="title-process ms-3">Design</h4>
                </div>
            
            <p>Create wireframes, prototypes, and visual design concepts for the client's approval.</p>
            </div>
                    </div>
                        
             
                <img src={Line2} alt="" className='img-fuild lines2' />
                <img src={Line4} alt="" className='img-fuild mobilelines' />
              
            
                    </div>

                </div>
                <div className="row flex-row-reverse rowp">
                <div className="col-md-6 align-items-center py-5">
               
                    <div className="col-md-9">
                   
                    <div className="cardp bg-white text-left">
                <div className="p-img-title d-flex align-items-center mb-3">
                <img src={p3} width={60} alt="" className='img-fluid' />
            <h4 className="title-process ms-3">Development</h4>
                </div>
            
            <p>Build and test the website, mobile app, or other software product, using appropriate coding languages, tools, and frameworks.</p>
            </div>
                    </div>
                    <img src={Line3} alt="" className='img-fuild lines3' />
                    <img src={Line4} alt="" className='img-fuild mobilelines' />
            
                    </div>

                <div className="col-md-6 align-items-center py-5">
                    <div className="col-md-9">
                    <div className="cardp bg-white text-left">
                <div className="p-img-title d-flex align-items-center mb-3">
                <img src={p4} width={60} alt="" className='img-fluid' />
            <h4 className="title-process ms-3">Quality Assurance</h4>
                </div>
            
            <p>Test the product to ensure it meets the client's requirements and is free of errors or bugs.</p>
            </div>
                    </div>
                        
             
                <img src={Line4} alt="" className='img-fuild lines4' />
                <img src={Line4} alt="" className='img-fuild mobilelines' />
              
            
                    </div>

                </div>
{/* third line process  */}
                <div className="row ">
                <div className="col-md-6 align-items-center py-5">
                    <div className="col-md-9">
                    <div className="cardp bg-white text-left">
                <div className="p-img-title d-flex align-items-center mb-3">
                <img src={p5} width={60} alt="" className='img-fluid' />
            <h4 className="title-process ms-3">Deployment</h4>
                </div>
            
            <p>Launch the product on the appropriate platform and perform any necessary post-launch tasks such as search engine optimization (SEO) or user acceptance testing (UAT).</p>
            </div>
                    </div>
                        
             
                <img src={Line6} alt="" className='img-fuild lines' />
                <img src={Line4} alt="" className='img-fuild mobilelines' />
              
            
                    </div>

                <div className="col-md-6 align-items-center py-5">
                    <div className="col-md-9">
                    <div className="cardp bg-white text-left">
                <div className="p-img-title d-flex align-items-center mb-3">
                <img src={p6} width={60} alt="" className='img-fluid' />
            <h4 className="title-process ms-3">Maintenance and Support</h4>
                </div>
            
            <p>Provide ongoing support, updates, and maintenance to ensure the product remains
functional and up-to-date.</p>
            </div>
                    </div>
                        
             
                <img src={Line5} alt="" className='img-fuild lines5' />
                <img src={Line4} alt="" className='img-fuild mobilelines' />
              
            
                    </div>

                </div>

                {/* last process */}
            <div className="d-flex justify-content-center pt-5">
            <div className="cardp bg-white text-left">
                <div className="p-img-title d-flex align-items-center mb-3">
                <img src={p7} width={60} alt="" className='img-fluid' />
            <h4 className="title-process ms-3">Digital Marketing</h4>
                </div>
          
            <p>Develop and execute a digital marketing strategy for clients that may include search engine optimization (SEO), pay-per-click (PPC) advertising, social media marketing.</p>
            </div>
            </div>





            </div>

           






            
          
      </Container>
    </section>
  )
}

export default Ourprocess;
