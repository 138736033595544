import React from 'react';
// import { NavLink } from 'react-router-dom';
import GsaImg from '../Assets/Graphics_design_about.svg';
import yesIcon from '../Assets/yes_icon.svg';
import InnerSlider from '../components/InnerSlide'
import Portfolio1_YGJeLYBA from '../components/portfolio/Graphics'
import dm_new_sec from "../Assets/graphics_new_ser.jpg";

function Graphics() {
  return (
    <>
  
   <InnerSlider slider="Graphics Design" />
      <div className="graphics_about py-5 container">
        <div className="row flex align-items-center row-gap-5">
          <div className="col-md-6">
          <div className="gsa_img text-center">
            <img src={GsaImg} alt="graphics design services" width={350} data-aos="fade-right" data-aos-duration="1000"/>
          </div>
          </div>
          <div className="col-md-6">
          <h2 className='title-sec mb-3' data-aos="fade-left" data-aos-duration="800">Elevate your brand with pixel-perfect creativity</h2>
              <p data-aos="fade-left" data-aos-duration="1000">Welcome to Prayosha Infotech, your one-stop destination for professional graphic design services. In today's digital age, visual communication plays a crucial role in capturing your audience's attention and conveying your brand message effectively. Our team of talented graphic designers is here to help you transform your ideas into stunning visual assets that leave a lasting impression.</p>
          </div>
        </div>
      </div>

      <div className="dm_new_sec d-flex align-items-center py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6" data-aos="fade-up-right" data-aos-duration="1000">
            <h2 className='title-sec mb-3 text-left text-white '>Our Graphic Design Services</h2>
            <ol>
              <li className="new_inner_sec_li">Logo Design:</li>
              <p className="text-white">Your logo is the face of your brand. Let us design a memorable and meaningful logo that represents your business.</p>
              <li className="new_inner_sec_li">Branding and Identity:</li>
              <p className="text-white">Our team can develop a cohesive brand identity that sets you apart from the competition.</p>
              <li className="new_inner_sec_li">Illustration</li>
              <p className="text-white">We craft custom illustrations that add a unique touch to your brand or project.</p>
              <li className="new_inner_sec_li">Social Media Graphics</li>
              <p className="text-white">Our team can develop a cohesive brand identity that sets you apart from the competition.</p>
            </ol>
            </div>
            <div className="col-md-6" data-aos="fade-up-left" data-aos-duration="1000">
            <img className="img-fluid" src={dm_new_sec} alt="" />
            </div>
          </div>
        </div>
            
      </div>
  
      <div className="whygraphics container py-5" data-aos="fade-up" data-aos-duration="1000">
      <h2 className='title-sec mb-3 text-center'>Why Choose Our Graphic Design Services</h2>

          <div className="row py-5 d-flex row-gap-4">
            <div className="col-md-6 d-grid gap-3">
              <div className="d-flex align-items-center gap-3">
                <div className="yesimg">
                  <img src={yesIcon} alt="yes icon" width={30} />
                </div>
                <h4>Creativity at its Best</h4>
              </div>
              <p>Our designers are creative visionaries who can bring your concepts to life. Whether you need a new logo, eye-catching marketing materials, or captivating social media graphics, we've got you covered.</p>
            </div>
            <div className="col-md-6 d-grid gap-3">
              <div className="d-flex align-items-center gap-3">
                <div className="yesimg">
                  <img src={yesIcon} alt="yes icon" width={30} />
                </div>
                <h4>Tailored Solutions</h4>
              </div>
              <p>We understand that every business is unique. That's why we work closely with you to understand your goals, values, and target audience to create custom designs that resonate with your brand identity.</p>
            </div>
            <div className="col-md-6 d-grid gap-3">
              <div className="d-flex align-items-center gap-3">
                <div className="yesimg">
                  <img src={yesIcon} alt="yes icon" width={30} />
                </div>
                <h4>High-Quality Design</h4>
              </div>
              <p>We are committed to delivering top-notch design work. Our team follows industry best practices and stays updated with the latest design trends to ensure your projects are of the highest quality.</p>
            </div>
            <div className="col-md-6 d-grid gap-3">
              <div className="d-flex align-items-center gap-3">
                <div className="yesimg">
                  <img src={yesIcon} alt="yes icon" width={30} />
                </div>
                <h4>Timely Delivery</h4>
              </div>
              <p>We value your time. Our efficient workflow and project management ensure that your designs are delivered on time, every time.</p>
            </div>
          </div>
        
      </div>


      <div className="graphics_work py-5" data-aos="fade-up" data-aos-duration="1000">
      <h2 className='title-sec  text-center'>Our Graphics Work</h2>

          <Portfolio1_YGJeLYBA />


      </div>
      

    </>
  )
}

export default Graphics;
