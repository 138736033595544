import React, { useState } from "react";
import Menu from "./menu";
import { Button } from "react-bootstrap";
import { Container, Row } from "react-bootstrap";
import Line from "../Assets/Line W.svg";
import "./techpro.css";

// ... (import statements)

function ImageGallery() {
  const [category, setCategory] = useState("frontend"); // Initial category

  const handleCategoryChange = (newCategory) => {
    setCategory(newCategory);
  };

  return (
    <>
      <div className="bgpro pt-5 pb-5 techpro-sec">
        <Container>
          <Row data-aos="zoom-in" data-aos-duration='800'>
            <div className="col-lg-12 pt-2 pb-2">
              <div className="sec-title text-center mb-5">
                <div className="line d-flex justify-content-center align-items-center mb-3">
                  <img src={Line} alt="" className="me-2 lineimgw" />
                  <h5 className="sec-titlew">Technical Proficiency</h5>
                </div>
                <h2 className="title-sec mb-3">
                  Partnering for Technical Solutions<br />
                  that Exceed Expectations.
                </h2>
                <div className="menu-tabs align-items-center d-flex flex-column justify-content-center container">
                  <div className="menu-tab py-4">
                    <Button
                      className={`mx-4 my-2 btntp ${
                        category === "frontend" ? "active-btn" : ""
                      }`}
                      onClick={() => handleCategoryChange("frontend")}
                    >
                      Frontend
                    </Button>
                    <Button
                      className={`mx-4 my-2 btntp ${
                        category === "backend" ? "active-btn" : ""
                      }`}
                      onClick={() => handleCategoryChange("backend")}
                    >
                      Backend
                    </Button>
                    <Button
                      className={`mx-4 my-2 btntp ${
                        category === "mobileapp" ? "active-btn" : ""
                      }`}
                      onClick={() => handleCategoryChange("mobileapp")}
                    >
                      Mobile App
                    </Button>
                    <Button
                      className={`mx-4 my-2 btntp ${
                        category === "database" ? "active-btn" : ""
                      }`}
                      onClick={() => handleCategoryChange("database")}
                    >
                     Database
                    </Button>
                    <Button
                      className={`mx-4 my-2 btntp ${
                        category === "cms" ? "active-btn" : ""
                      }`}
                      onClick={() => handleCategoryChange("cms")}
                    >
                      CMS
                    </Button>
                    <Button
                      className={`mx-4 my-2 btntp ${
                        category === "Server" ? "active-btn" : ""
                      }`}
                      onClick={() => handleCategoryChange("Server")}
                    >
                      Server
                    </Button>

                  </div>
                </div>

                {/* Display filtered items */}
                <div className="menu-items container ">
                  <div className="row">
                    <div className="col-12">
                      <div className="row">
                        {Menu.map((item) => (
                          <div
                            key={item.id}
                            className={`item1 col-6 col-md-3 col-lg-3 col-xl-3 my-4 ${
                              item.category === category ? "" : "d-none"
                            }`}
                          >
                            <img
                              src={item.image}
                              className="img-fluid my-2"
                              alt={item.category}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default ImageGallery;
