import React from "react";
import InnerSlider from "../components/InnerSlide"
import Web_Img from "../Assets/Web_img.svg";
import yesIcon from '../Assets/yes_icon.svg';
import dm_new_sec from "../Assets/web_new_ser.jpg";

const Web = () => {
  return <div>
    <InnerSlider slider="Web Development" />
    <div className="graphics_about py-5 container">
        <div className="row flex align-items-center row-gap-5">
        <div className="col-md-6">
          <div className="gsa_img text-center">
            <img src={Web_Img} alt="graphics design services" width={350} data-aos="fade-right" data-aos-duration="1000"/>
          </div>
          </div>
          <div className="col-md-6">
          <h2 className='title-sec mb-3' data-aos="fade-left" data-aos-duration="800">Transforming Ideas into Websites</h2>
              <p data-aos="fade-left" data-aos-duration="1000">Welcome to Prayosha Infotech, your trusted partner for professional web development services. In the digital age, your website is often the first point of contact with your audience. It's not just a virtual storefront; it's the face of your business. Our team of skilled developers is here to turn your web dreams into a reality, ensuring your online presence is not only visually stunning but also highly functional and user-friendly.</p>
          </div>
          
        </div>
      </div>

      <div className="dm_new_sec py-5 d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-md-6" data-aos="fade-right" data-aos-duration="1000">
            <h2 className='title-sec mb-3 text-left text-white '>Our Web Development Services</h2>
            <ol>
              <li className="new_inner_sec_li">Website Design and Development:</li>
              <p className="text-white">From concept to deployment, we create visually striking and functional websites tailored to your needs.</p>
              <li className="new_inner_sec_li">E-Commerce Solutions:</li>
              <p className="text-white">Launch and manage your online store with our robust e-commerce development expertise.</p>
              <li className="new_inner_sec_li">Content Management Systems (CMS):</li>
              <p className="text-white">We provide CMS solutions like WordPress and Drupal, allowing you to easily update and manage your website's content.</p>
              <li className="new_inner_sec_li">Website Maintenance and Support:</li>
              <p className="text-white">We offer ongoing maintenance and support services to keep your website up-to-date and secure.</p>
              
            </ol>
            </div>
            <div className="col-md-6" data-aos="fade-left" data-aos-duration="1000">
            <img className="img-fluid" src={dm_new_sec} alt="" />
            </div>
          </div>
        </div>
            
      </div>
  
      <div className="whygraphics container py-5" data-aos="fade-up" data-aos-duration="1000">
      <h2 className='title-sec mb-3 text-center'>Why Choose Our Web Development Services</h2>

          <div className="row py-5 d-flex row-gap-4">
            <div className="col-md-6 d-grid gap-3">
              <div className="d-flex align-items-center gap-3">
                <div className="yesimg">
                  <img src={yesIcon} alt="yes icon" width={30} />
                </div>
                <h4>Custom Solutions</h4>
              </div>
              <p>We understand that every business is unique. That's why we tailor our web development solutions to your specific needs, ensuring your website aligns perfectly with your brand and objectives.</p>
            </div>
            <div className="col-md-6 d-grid gap-3">
              <div className="d-flex align-items-center gap-3">
                <div className="yesimg">
                  <img src={yesIcon} alt="yes icon" width={30} />
                </div>
                <h4>Cutting-Edge Technology</h4>
              </div>
              <p>Our developers stay at the forefront of technology trends, using the latest tools and frameworks to build modern, responsive, and secure websites.</p>
            </div>
            <div className="col-md-6 d-grid gap-3">
              <div className="d-flex align-items-center gap-3">
                <div className="yesimg">
                  <img src={yesIcon} alt="yes icon" width={30} />
                </div>
                <h4>User-Centric Design</h4>
              </div>
              <p>We believe that user experience is paramount. Our designs are not just visually appealing but also intuitively navigable, ensuring a seamless experience for your visitors.</p>
            </div>
            <div className="col-md-6 d-grid gap-3">
              <div className="d-flex align-items-center gap-3">
                <div className="yesimg">
                  <img src={yesIcon} alt="yes icon" width={30} />
                </div>
                <h4>Scalability and Performance </h4>
              </div>
              <p>We build websites with scalability in mind, so your online presence can grow as your business expands. Our focus on performance optimization guarantees fast-loading pages.</p>
            </div>
          </div>
        
      </div>



  </div>;
};

export default Web;
