import {React, useState} from 'react';
import {Modal, ModalHeader} from 'reactstrap';
import { ModalBody } from "react-bootstrap";
import Alert from 'react-bootstrap/Alert';



const Rfqmodel = (props) => {
  const [showAlert, setShowAlert] = useState(false);

  const handleSubmit = () => {
   
   
    setShowAlert(true);

    props.setModal(false);


    setTimeout(() => {
      setShowAlert(false);
    }, 3000);}

  return (
    <div>
    <Modal
    size="small" 
      isOpen={props.modal}
      toggle={()=>props.setModal(!props.modal)}
    >
      <ModalHeader
        toggle={()=>props.setModal(!props.modal)}
      >
        Request A Quote
      </ModalHeader>
      <ModalBody>
      <form  action="mail.php" method="post">

                <label for="name">Your Name</label>
                <input className='form-control mb-3' type="text" name="name" placeholder="Enter Your name.."/>
                <label for="lname">Email</label>
                <input className='form-control mb-3' type="email" name="email" placeholder="Enter Your email.."/>
                <label for="mobile">Mobile</label>
                <input className='form-control mb-3' type="mobile" name="mobile" placeholder="Enter Your Mobile.."/>
                <label for="message">Message</label>
                <textarea className='form-control mb-3' name="message" placeholder="Write something.."></textarea>
                <input className='form-control' type="submit" class="btn" value="Submit" onClick={handleSubmit} />
            </form>
      </ModalBody>
    </Modal>

    {showAlert && (
        <Alert variant='success' onClose={() => setShowAlert(false)} dismissible>
          Whenever you need to, be
        </Alert>
      )}
  </div>
  );
}


export default Rfqmodel