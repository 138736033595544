import React from 'react';
import { Container } from 'react-bootstrap';
import Line from "../Assets/Line 9.svg";
import Coma from '../Assets/“.svg'
import Carousel from 'react-bootstrap/Carousel';
import './testi.css';
import khodal from '../Assets/khodal.png';
import shlok from "../Assets/dr.png";
import yogi from '../Assets/yogi.png'
import akshar from '../Assets/akshar.png';
import paridhanam from '../Assets/paridhanam.jpeg';
import omkudrat from '../Assets/om kudrat.jpeg';
import vita from "../Assets/vita.jpeg";
import corncob from "../Assets/corncob.jpeg";
import mahanmol from '../Assets/mahanmol.jpeg';
import cany from '../Assets/canyon.png';




const Testimonials = () => {
  
 
  return (
    <section className='Testimonials py-5'>
<div className="container container1 h-100">
  <div className="row align-items-center h-100">
  <div className="line d-flex justify-content-center align-items-center mb-3">
            <img src={Line} alt="" className='me-2 lineimg'/><h5 className='sec-titleb'>Our Clients</h5>
           
            </div>
            <h2 className='title-sec text-center mb-5'>Clients Who Believe in Us</h2>
    <div className="container rounded">
      <div className="slider">
        <div className="logos">
          <img src={cany} alt="" className='mx-4' width={150} />
          <img src={corncob} alt="" className='mx-4' width={150}/>
          <img src={mahanmol} alt=" className='mx-4'"width={150} />
          <img src={khodal} alt="" className='mx-4' width={150}/>
          <img src={yogi} alt="" className='mx-4' width={150}/>
          <img src={akshar} alt="" className='mx-4' width={150}/>
          <img src={paridhanam} alt="" className='mx-4' width={150}/>
          <img src={shlok} alt="" className='mx-4' width={150}/>
          <img src={omkudrat} alt="" className='mx-4' width={150}/>
          <img src={vita} alt="" className='mx-4' width={150}/>
        </div>
        <div className="logos">
       
        </div>
      </div>
    </div>
  </div>

</div>

 
   

















      {/*
      <Container>
        <div className="sec-title text-center mb-5">
          <div className="line d-flex justify-content-center align-items-center mb-3">
            <img src={Line} alt="" className='me-2 lineimg' /><h5 className='sec-titleb'>Our Testimonials</h5>

          </div>
          <h2 className='title-sec'>What our customers are talking
            about us</h2>
          <img src={Coma} alt="" className='img-fluid py-4' />
          
          
          <Carousel data-bs-theme="dark" indicators={false} className='text-center'>
      <Carousel.Item>
         
         <div className="card-testi">
                  <p>“Partnering with Praysoha Infotech has been a game-changer for our IT business. Their design expertise, from logos to videos, has given our brand a visual identity that truly stands out. Premal Patel's professionalism and commitment to delivering top-notch designs have made them team an essential part of our success story.”</p>

                  <img src={khodal} alt="" className='img-fluid py-3' width={200} />
                </div>
         
      </Carousel.Item>
      <Carousel.Item>
     
        <div className="card-testi">
                  <p>“I've been consistently impressed by prayosha infotech's graphic design work.Your Team has an innate ability to turn our ideas into stunning visuals – whether it's banners, logos, or videos. The team's dedication to quality and their knack for capturing our brand essence have made them our go-to design experts.”</p>

                  <img src={shlok} alt="" className='img-fluid py-3' width={120} />

              
                </div>
           
      </Carousel.Item>
      <Carousel.Item>
        
         <div className="card-testi">
                  <p>“Working with  Prayosha Infotech has been an absolute pleasure. Their graphic design services, from logos to social media posts, are unparalleled. The team's creativity and professionalism shine through in every project. Thanks to Premal Patel, our brand's visual identity has never been stronger.”</p>

                  <img src={yogi} alt="" className='img-fluid py-3' width={120} />

                
                </div>
        
      </Carousel.Item>
      <Carousel.Item>
  
         <div className="card-testi">
                  <p>“Working with  Prayosha Infotech has been an absolute pleasure. Their graphic design services, from logos to social media posts, are unparalleled. The team's creativity and professionalism shine through in every project. Thanks to Premal Patel, our brand's visual identity has never been stronger.”</p>

                  <img src={akshar} alt="" className='img-fluid py-3' width={200} />

                
                </div>
              
      </Carousel.Item>
      <Carousel.Item>
         
         <div className="card-testi">
                  <p>“Working with  Prayosha Infotech has been an absolute pleasure. Their graphic design services, from logos to social media posts, are unparalleled. The team's creativity and professionalism shine through in every project. Thanks to Premal Patel, our brand's visual identity has never been stronger.”</p>

                  <img src={paridhanam} alt="" className='img-fluid py-3' width={180} />

                
                </div>
              
      </Carousel.Item>
      <Carousel.Item>
      
         <div className="card-testi">
                  <p>“Working with  Prayosha Infotech has been an absolute pleasure. Their graphic design services, from logos to social media posts, are unparalleled. The team's creativity and professionalism shine through in every project. Thanks to Premal Patel, our brand's visual identity has never been stronger.”</p>

                  <img src={cany} alt="" className='img-fluid py-3' width={180} />

                
                </div>
            
      </Carousel.Item>
      <Carousel.Item>
       
         <div className="card-testi">
                  <p>“Working with  Prayosha Infotech has been an absolute pleasure. Their graphic design services, from logos to social media posts, are unparalleled. The team's creativity and professionalism shine through in every project. Thanks to Premal Patel, our brand's visual identity has never been stronger.”</p>

                  <img src={corncob} alt="" className='img-fluid py-3' width={120} />

                
                </div>
              
      </Carousel.Item>
      <Carousel.Item>
        
         <div className="card-testi">
                  <p>“Working with  Prayosha Infotech has been an absolute pleasure. Their graphic design services, from logos to social media posts, are unparalleled. The team's creativity and professionalism shine through in every project. Thanks to Premal Patel, our brand's visual identity has never been stronger.”</p>

                  <img src={mahanmol} alt="" className='img-fluid py-3' width={180} />

                
                </div>
               
      </Carousel.Item>
      <Carousel.Item>
     
         <div className="card-testi">
                  <p>“Working with  Prayosha Infotech has been an absolute pleasure. Their graphic design services, from logos to social media posts, are unparalleled. The team's creativity and professionalism shine through in every project. Thanks to Premal Patel, our brand's visual identity has never been stronger.”</p>

                  <img src={omkudrat} alt="" className='img-fluid py-3' width={180} />

                
                </div>
              
      </Carousel.Item>
      <Carousel.Item>
         
         <div className="card-testi">
                  <p>“Working with  Prayosha Infotech has been an absolute pleasure. Their graphic design services, from logos to social media posts, are unparalleled. The team's creativity and professionalism shine through in every project. Thanks to Premal Patel, our brand's visual identity has never been stronger.”</p>

                  <img src={vita} alt="" className='img-fluid py-3' width={200} />

                
                </div>
           
      </Carousel.Item>
    </Carousel>

        </div>
      </Container> */}
    </section>
  )
}

export default Testimonials;