const Menu = [
    {
        id: 1,
        image: "images/angular.png",
        category: "frontend"
    },
    {
        id: 2,
        image: "images/dotnet.png",
        category: "backend"
    },
    {
        id: 3,
        image: "images/flutter.png",
        category: "mobileapp"
    },
    {
        id: 4,
        image: "images/firebase.png",
        category: "database"
    },
    {
        id: 5,
        image: "images/wp.png",
        category: "cms"
    },
    {
        id: 6,
        image: "images/aws.png",
        category: "Server"
    },
    {
        id: 6,
        image: "images/azure.png",
        category: "Server"
    },
    {
        id: 1,
        image: "images/boostrap.png",
        category: "frontend"
    },
    {
        id: 1,
        image: "images/css.png",
        category: "frontend"
    },
    {
        id: 6,
        image: "images/gcloud.png",
        category: "Server"
    },
    {
        id: 1,
        image: "images/html.png",
        category: "frontend"
    },
    {
        id: 2,
        image: "images/java.png",
        category: "backend"
    },
    {
        id: 2,
        image: "images/node.png",
        category: "backend"
    },
    {
        id: 2,
        image: "images/php.png",
        category: "backend"
    },
    {
        id: 1,
        image: "images/js.png",
        category: "frontend"
    },
    {
        id: 4,
        image: "images/mongodb.png",
        category: "database"
    },
    {
        id: 4,
        image: "images/mysql.png",
        category: "database"
    },
    {
        id: 3,
        image: "images/java.png",
        category: "mobileapp"
    },
    {
        id: 2,
        image: "images/python.png",
        category: "backend"
    },
    {
        id: 3,
        image: "images/react native.png",
        category: "mobileapp"
    },
    {
        id: 1,
        image: "images/react.png",
        category: "frontend"
    },
    {
        id: 5,
        image: "images/shopify.png",
        category: "cms"
    },
    {
        id: 4,
        image: "images/sqlite.png",
        category: "database"
    },
    {
        id: 3,
        image: "images/swift.png",
        category: "mobileapp"
    },
    {
        id: 1,
        image: "images/vue.png",
        category: "frontend"
    },
]

export default Menu;