import React from 'react'
import InnerSlider from "../components/InnerSlide"
import Portfolio1_YGJeLYBA from '../components/portfolio/Graphics'
import Uiux_portfolio from '../components/portfolio/Uiux'


function Porfolio() {
  return (
    <div>
     <InnerSlider slider="Portfolio" />
     <div className="graphics_work py-5" data-aos="fade-up" data-aos-duration="1000">
      <h2 className='title-sec  text-center'>Our Graphics Work</h2>

          <Portfolio1_YGJeLYBA />


      </div>
     <div className="graphics_work py-5"  data-aos="fade-up" data-aos-duration="1000">
      <h2 className='title-sec  text-center'>Our Graphics Work</h2>

          <Uiux_portfolio />


      </div>
    </div>
  )
}

export default Porfolio
