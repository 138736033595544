import React from 'react'
import InnerSlider from '../components/InnerSlide';
import Line from "../Assets/Line 9.svg";
import './new.css'
import { Form, Button, Container,   } from 'react-bootstrap';
import {
  
  RiMailFill,
  RiPhoneFill,
  RiMapPinFill,
  RiCalendar2Line
} from "react-icons/ri";

function Contcatus() {
  return (
    <div>
         <InnerSlider slider="Contact Us" />

      <div className="contact py-5" data-aos="fade-up" data-aos-duration="1000">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
              <div className="line d-flex align-items-center mb-3">
            <img src={Line} alt="" className='me-2 lineimg'/><h5 classNameName='sec-titleb'>Reach Out</h5>
            </div>
            <h2 className='title-sec mb-3'>Contact Our Agency</h2>

          <div className="contact-sec fs-5 d-grid gap-3">
          <div className="d-flex align-items-center">
                <RiPhoneFill className="contact_sec_icon" />
                <p className="m-0 ms-2">+91 99099 95536</p>
                </div>
                <div className="d-flex align-items-center">
                <RiMailFill className="contact_sec_icon" />
                <p className="m-0 ms-2">info@prayoshainfotech.in</p>
                </div>
                <div className="d-flex align-items-start">
                <RiMapPinFill className="contact_sec_icon" />
                <p className="m-0 ms-2"> 3rd floor, Ram Sikhar Complex, Opp. Downtown <br /> TVS Showroom,
                  Grid Chowkdi, Anand</p>
                </div>

                <div className="map-responsive">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3684.5929510486544!2d72.95019027587162!3d22.556915933543916!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x210c9b6ffa28021b%3A0xfb3286b8b1138074!2sPrayosha%20Infotech!5e0!3m2!1sen!2sin!4v1716719244627!5m2!1sen!2sin"
                    width="100%"
                    height="350"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  />
                </div>


               
          </div>

           


              </div>
              <div className="col-md-6">
              <h2 className="mb-4 text-center">Contact Us</h2>
          <Form>
            <Form.Group controlId="formName">
              <Form.Label>Your Name</Form.Label>
              <Form.Control type="text" placeholder="Enter your name" />
            </Form.Group>

            <Form.Group controlId="formEmail" className="mt-3">
              <Form.Label>Your Email</Form.Label>
              <Form.Control type="email" placeholder="Enter your email" />
            </Form.Group>

            <Form.Group controlId="formPhone" className="mt-3">
              <Form.Label>Your Phone</Form.Label>
              <Form.Control type="tel" placeholder="Enter your phone number" />
            </Form.Group>

            <Form.Group controlId="formMessage" className="mt-3">
              <Form.Label>Your Message</Form.Label>
              <Form.Control as="textarea" rows={5} placeholder="Enter your message" />
            </Form.Group>

            <Button variant="primary" type="submit" className="mt-4">
              Submit
            </Button>
          </Form>

              </div>
            </div>
          </div>

      </div>

        


      
    </div>
  )
}

export default Contcatus
