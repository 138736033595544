import React, { useState } from 'react';
import C1 from "../Assets/c1.svg";
import C2 from "../Assets/c2.svg";
import C3 from "../Assets/c3.svg";
import C4 from "../Assets/c4.svg";
import './counter.css';
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';


const Counter = () => {

    const [counterOn, setCounterOn] = useState(false);

  return (
  <ScrollTrigger onEnter={()=>setCounterOn(true)} onExit={()=>setCounterOn(false)}>

<div className='counterbg py-5'>
      <div className="container py-2">
        <div className="row ">
          <div className="col-md-3 text-center text-white">
            <img src={C1} alt="" className='img-fluid mb-3' width={80} />
            <h1 className='numbercounter'>
            {counterOn && <CountUp start={0} end={2531} duration={1} delay={0} /> }
              </h1>
            <p>Project Finished</p>
          </div>
          <div className="col-md-3 text-center text-white">
            <img src={C2} alt="" className='img-fluid mb-3' width={80} />
            <h1 className='numbercounter'>
            {counterOn && <CountUp start={0} end={15} duration={1} delay={0} /> }
              +</h1>
            <p>
Years Experience</p>
          </div>
          <div className="col-md-3 text-center text-white">
            <img src={C3} alt="" className='img-fluid mb-3' width={80} />
            <h1 className='numbercounter'>
              {counterOn && <CountUp start={0} end={280} duration={1} delay={0} /> }
              
</h1>
            <p>Happy Clients</p>
          </div>
          <div className="col-md-3 text-center text-white">
            <img src={C4} alt="" className='img-fluid mb-3' width={80} />
            <h1 className='numbercounter'>
            {counterOn && <CountUp start={0} end={3587} duration={1} delay={0} /> }
              </h1>
            <p>Recognition</p>
          </div>
        </div>
      </div>

    </div>



  </ScrollTrigger>

    
  )
}

export default Counter