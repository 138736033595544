import {React, useState} from 'react';
import { Button, Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import  Vector  from "../Assets/Group 43.svg";
import "./home.css";
import Line from "../Assets/Line 9.svg";
import Gd from "../Assets/gd.svg";
import Ad from "../Assets/Appd.svg";
import Dm from "../Assets/DM.svg";
import Ui from "../Assets/ui.svg";
import Wd from "../Assets/wd.svg";
import About1 from "../Assets/about1.png";
import About2 from "../Assets/about2.png";
// import Techpro from '../components/Techpro';
import ImageGallery from '../components/ImageGallery';
import Ourprocess from '../components/Ourprocess';
import Testimonials from '../components/Testimonials';
import Counter from '../components/Counter';
import FAQ from '../components/FAQ';
import Rfqmodel from '../components/Rfqmodel';
// import Techpro from '../components/Techpro';
import { NavLink } from 'react-router-dom';


function Home(props) {
  const [modal, setModal] = useState(false);
  
  return (
    <div>
    
      <Container>
        <Row>
          <div  className="col-md-6 align-self-center justify-content-center flex-column pt-5 pb-5 s1text">
            <h5 data-aos="fade-up" data-aos-duration="1000" className='subtitle'>Welcome to</h5>
            <h1 data-aos="fade-up" data-aos-duration="1000" className='title'>Prayosha Infotech</h1>
            <p data-aos="fade-up" data-aos-duration="1000">Your Trusted partner in Web design, Graphic design, and
Digital Marketing</p>
      <Button data-aos="fade-up" data-aos-duration="1000" className='btnh1' onClick={()=> setModal(true)}>Get started →</Button>
          </div>
          <div className="col-md-6 d-flex justify-content-center z-index-1  pt-5 pb-5"><img  data-aos="zoom-in-up" data-aos-duration="1000" src={Vector} alt="" className='img-fluid updown-animation' /></div>
        </Row>
      </Container>
      <section className='services-sec pt-5 pb-2'>
        <Container>
          <Row>
        <div  data-aos="zoom-in-up"data-aos-duration="800" className="col-lg-12 pt-2 pb-2">
          <div className="sec-title text-center mb-5">
            <div className="line d-flex justify-content-center align-items-center mb-3">
            <img src={Line} alt="" className='me-2 lineimg'/><h5 className='sec-titleb'>What we can do for you</h5>
           
            </div>
            <h2 className='title-sec mb-3'>Services we can help you with</h2>

            <div className="servicecontent">
              <div className="sc">
                  <img src={Gd} alt=""width={120} className='img-fluid' />
                  <h4>Graphic Design</h4>
              </div>
              <div className="sc">
                  <img src={Ui} alt=""width={120}  className='img-fluid' />
                  <h4>UI & UX Design</h4>
              </div>
              <div className="sc">
                  <img src={Wd} alt=""width={120}  className='img-fluid'/>
                  <h4>Web Development</h4>
              </div>
              <div className="sc">
                  <img src={Ad} alt=""width={120}  className='img-fluid'/>
                  <h4>App Development</h4>
              </div>
              <div className="sc">
                  <img src={Dm} alt="" width={120} className='img-fluid' />
                  <h4>Digital Marketing</h4>
              </div>
            </div>
            
          </div>
        </div>
          </Row>
        </Container>
      </section>

       {/* About Section */}

      <section>
      <Container>
          <Row>

            <div  data-aos="fade-up" data-aos-duration="1000" className="col-md-6 about-sec">
              <img src={About1} alt="" width={450} className='img-fluid rounded-4 about1'/>
              <img src={About2} alt="" width={300} className='about2 img-fluid' />
            </div>


            <div data-aos="fade-up" data-aos-duration="1000" className="col-md-6 about-sec">
            <div className="line d-flex align-items-center mb-3">
            <img src={Line} alt="" className='me-2 lineimg'/><h5 className='sec-titleb'>About Us</h5>
           
            </div>
            <h2 className='title-sec mb-3'>Make it easy to do your business
with us and we are best</h2>
          <p>Prayosha infotech is a digital agency that specializes in helping businesses grow in the digital world. Our team of skilled designers, developers, and marketers work closely with our clients to develop customized solutions that are tailored to their unique needs. We believe in transparency, honesty, and open communication, and we're committed to delivering high quality work and exceptional customer service. Our services include web design, graphic design, digital marketing, web development, and app development UI/UX. Contact us today to learn how we can help your business achieve success in the digital world.</p>
          <NavLink to='/AboutUs'><Button>Discover More → </Button></NavLink>


            </div>

          </Row>
        </Container>
      </section>

      {/* Technical proficiency */}
      {/* <Techpro/> */}
      <ImageGallery  />

      {/* our process */}
      <Ourprocess />

      {/* testimonials */}
      <Testimonials />
      {/* <Techpro /> */}

      {/* counter */}
      <Counter />

      {/* FAQ  */}
    <FAQ />

    <Rfqmodel modal={modal} setModal={setModal} />
    </div>
  )
}

export default Home
