import React from 'react'
import {NavLink} from 'react-router-dom'
export default function InnerSlide(props) {
  return (
    <>
      
      <div className="innerslider py-5">
                <div className="inner-content d-flex flex-column align-items-center justify-content-center">
                  <h3 className='text-white'>{props.slider}</h3>
                 <div className="slider-links d-flex">
                  <NavLink to="/" className='slider-link'>Home</NavLink> / <p>{props.slider}</p>
                  </div>
                 
                </div>
           </div>
    </>
  )
}
