import React from 'react';
import { Button, Container } from 'react-bootstrap';
import Row from "react-bootstrap/Row";
import Line from "../Assets/Line 9.svg";
import About1 from "../Assets/about1.png";
import About2 from "../Assets/about2.png";
import { NavLink } from 'react-router-dom';
import Counter from '../components/Counter';
import mansi from "../Assets/mansi.png"
import FAQ from '../components/FAQ';
import nirmal from '../Assets/nirmal.png';
import krishna from '../Assets/krishna.png'
import founder from '../Assets/Premal Sir.png';
import user from '../Assets/user.png'
import {RiLinkedinBoxFill} from "react-icons/ri";
import InnerSlider from '../components/InnerSlide'
function Aboutus() {
  return (
    <div>
      <InnerSlider slider="About Us" />

      
       {/* About Section */}

       <section>
      <Container>
          <Row>

            <div  data-aos="fade-up" data-aos-duration="1000" className="col-md-6 about-sec">
              <img src={About1} alt="" width={450} className='img-fluid rounded-4 about1'/>
              <img src={About2} alt="" width={300} className='about2 img-fluid' />
            </div>


            <div data-aos="fade-up" data-aos-duration="1000" className="col-md-6 about-sec">
            <div className="line d-flex align-items-center mb-3">
            <img src={Line} alt="" className='me-2 lineimg'/><h5 className='sec-titleb'>About Us</h5>
           
            </div>
            <h2 className='title-sec mb-3'>Make it easy to do your business
with us and we are best</h2>
          <p>Prayosha infotech is a digital agency that specializes in helping businesses grow in the digital world. Our team of skilled designers, developers, and marketers work closely with our clients to develop customized solutions that are tailored to their unique needs. We believe in transparency, honesty, and open communication, and we're committed to delivering high quality work and exceptional customer service. Our services include web design, graphic design, digital marketing, web development, and app development UI/UX. Contact us today to learn how we can help your business achieve success in the digital world.</p>
          <Button>Discover More →</Button>


            </div>

          </Row>
        </Container>
      </section>
{/* our Team */}
      <section className='our_team py-5'>
      <div data-aos="zoom-in-up" data-aos-duration="1000" className="sec-title text-center mb-5"  >
      <div className="line d-flex justify-content-center align-items-center mb-3">
            <img src={Line} alt="" className='me-2 lineimg'/><h5 className='sec-titleb'>Our Team</h5>
           
            </div>
            <h2 className='title-sec mb-3'>Meet our awesome and professional team</h2>
      </div>
      <div data-aos="zoom-in-up"data-aos-duration="800"  className="all_team">
        <div className="container">
          <div className="founder-sec d-flex justify-content-center mb-4">
             
              <div className="card_founder">
              <div className="row">
                <div className="col-md-6 text-center">
                  <img src={founder} alt="" className='img-fluid' width={400} />
                </div>
                <div className="col-md-6 text-center d-flex flex-column justify-content-center align-items-center">
                <h3 className='mt-3 team_name'>Premal Patel</h3>
            <h6 className='team_des'>Founde/CEO</h6>
          <a href="https://www.linkedin.com/in/premal-patel-733123289/" target='_blank'><RiLinkedinBoxFill className='team_in mt-2' /></a>
                </div>
              </div>
     
            </div>
          </div>
      <div className="row">
        <div className="col-md-4 my-3">
          <div className="card_team text-center">
            <img src={nirmal} alt="" className='img-fluid' width={180} />
            <h3 className='mt-3 team_name'>Nirmal Rathod</h3>
            <h6 className='team_des'>Team-lead (Web development)</h6>
            <a href="https://www.linkedin.com/in/nirmalkumarrathod/" target='_blank'><RiLinkedinBoxFill className='team_in mt-2' /></a>
          </div>
        </div>
        <div className="col-md-4 my-3">
          <div className="card_team text-center">
            <img src={mansi} alt="" className='img-fluid' width={180} />
            <h3 className='mt-3 team_name'>Mansi Rana</h3>
            <h6 className='team_des'>Team-lead (Graphics Design)</h6>
            <RiLinkedinBoxFill className='team_in mt-2' />
          </div>
        </div>
        <div className="col-md-4 my-3">
          <div className="card_team text-center">
            <img src={krishna} alt="" className='img-fluid' width={180} />
            <h3 className='mt-3 team_name'>Krishna Patel</h3>
            <h6 className='team_des'>Team-lead (UI/UX Designer)</h6>
            <RiLinkedinBoxFill className='team_in mt-2' />
          </div>
        </div>
        <div className="col-md-4 my-3">
          <div className="card_team text-center">
            <img src={user} alt="" className='img-fluid' width={180} />
            <h3 className='mt-3 team_name'>Yasmin Shaikh</h3>
            <h6 className='team_des'>Marketing Head</h6>
            <RiLinkedinBoxFill className='team_in mt-2' />
          </div>
        </div>
        <div className="col-md-4 my-3">
          <div className="card_team text-center">
            <img src={user} alt="" className='img-fluid' width={180} />
            <h3 className='mt-3 team_name'>Jainik Sisodiya</h3>
            <h6 className='team_des'>Graphics Designer</h6>
            <RiLinkedinBoxFill className='team_in mt-2' />
          </div>
        </div>
        <div className="col-md-4 my-3">
          <div className="card_team text-center">
            <img src={user} alt="" className='img-fluid' width={180} />
            <h3 className='mt-3 team_name'>Jastin Pithadiya</h3>
            <h6 className='team_des'>Python Developer</h6>
            <RiLinkedinBoxFill className='team_in mt-2' />
          </div>
        </div>
      </div>
        </div>
       
      </div>
      </section>

      <Counter />
      <FAQ />
    </div>
  )
}

export default Aboutus
