import './App.css';
import {React}from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './components/Header';
import Home from "./pages/Home";
import Graphics from "./pages/Graphics";
import About from "./pages/Aboutus";
import Portfolio from "./pages/Porfolio";
import Contact from "./pages/Contcatus";
import UiuxDesign from "./pages/Uiux";
import Web from "./pages/Web";
import Appd from "./pages/Appd";
import Dm from "./pages/DM";
import Footer from "./components/Footer";
import {
  BrowserRouter,
  Routes,
  Route

} from "react-router-dom";
// import Alert from './components/alert'



function App() {
  

  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <div className='App'>
        
        <Header />
       
        <Routes>
          <Route exact path="/"  Component={Home} />
          <Route exact path="/WhatWeDo/GraphicsDesign"  Component={Graphics} />
          <Route exact path="/WhatWeDo/UiuxDesign"  Component={UiuxDesign} />
          <Route exact path="/WhatWeDo/WebDevelopment"  Component={Web} />
          <Route exact path="/Whatwedo/DigitalMarketing"  Component={Dm} />
          <Route exact path="/Whatwedo/AppDevelopment"  Component={Appd} />
          <Route exact path="/AboutUs"  Component={About} />
          <Route exact path="/Porfolio"  Component={Portfolio} />
          <Route exact path="/ContactUs"  Component={Contact} />
         
        </Routes>
        
      <Footer />
      </div>

    </BrowserRouter>
  );
}

export default App;
