import React from "react";
import "./footer.css";
import Logo from "../Assets/infotech white 1.png";
import {
  RiInstagramLine,
  RiLinkedinFill,
  RiFacebookFill,
  RiTwitterFill,
  RiMailFill,
  RiPhoneFill,
  RiMapPinFill,
  RiCalendar2Line
} from "react-icons/ri";
import { Link, NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <footer className=" footerbg">
      <div className="container pt-5">
        <div className="row">
          <div className="col-12 col-lg-3 col-6 footer1">
            <img src={Logo} alt="" className="img-fluid mb-4" width={180} />
            <p>
              Our mission is to help businesses grow in the digital world by
              providing customized solutions tailored to each client's unique
              needs.
            </p>
            <div className="socialicons mt-4">
              <ul>
                <li>
                  <a href="#">
                    <RiTwitterFill />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <RiFacebookFill />
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/prayoshainfotech" target="_blank">
                    <RiLinkedinFill />
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/prayoshacreative" target="_blank">
                    <RiInstagramLine />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 footer-flex-box ">
          <div className=" footer1">
                <h5 className="footer-title mb-4">Our Services</h5>
                <NavLink
                  to="/WhatWeDo/AppDevelopment"
                  className={"footerlink "}
                >
                  <p className="footerm">App Development</p>{" "}
                </NavLink>
                <NavLink to="/WhatWeDo/WebDevelopment" className={"footerlink"}>
                  <p className="footerm">Web Development</p>{" "}
                </NavLink>
                <NavLink to="/WhatWeDo/UiuxDesign" className={"footerlink"}>
                  <p className="footerm">UI/UX Design</p>
                </NavLink>
                <NavLink
                  to="/WhatWeDo/DigitalMarketing"
                  className={"footerlink"}
                >
                  <p className="footerm">Digital Marketing</p>
                </NavLink>
                <NavLink to="/WhatWeDo/GraphicsDesign" className={"footerlink"}>
                  <p className="footerm">Graphics Design</p>
                </NavLink>
              </div>
          </div>
      <div className="col-lg-3 col-md-6 footer-flex-box2">
      <div className=" footer1">
                <h5 className="footer-title mb-4">Quick Links</h5>
                <NavLink
                  to="/"
                  className={"footerlink "}
                >
                  <p className="footerm">Home</p>{" "}
                </NavLink>
                <NavLink to="/AboutUs" className={"footerlink"}>
                  <p className="footerm">About Us</p>{" "}
                </NavLink>
                <NavLink
                  to="/Porfolio" 
                  className={"footerlink"}
                >
                  <p className="footerm">Portfolio</p>
                </NavLink>
                <NavLink to="/ContactUs" className={"footerlink"}>
                  <p className="footerm">Contact Us</p>
                </NavLink>
              </div>
      </div>
      <div className="col-12 col-lg-3 col-md-6 footer-flex-box">
      <div className=" footer1">
                <h5 className="footer-title mb-4">Contact Us</h5>
             
                <div className=" d-flex align-items-center">
                <RiPhoneFill className="footericons" />
                <p className="m-0 ms-2">+91 99099 95536</p>
                </div>
                <div className=" d-flex align-items-center">
                <RiMailFill className="footericons" />
                <p className="m-0 ms-2">info@prayoshainfotech.in</p>
                </div>
                <div className=" d-flex">
                <RiMapPinFill className="footericon" />
                <p className="m-0 ms-2"> 3rd floor, Ram Sikhar Complex, Opp. Downtown TVS Showroom,
                  Grid Chowkdi, Anand</p>
                </div>
                <div className=" d-flex align-items-center">
                <RiCalendar2Line className="footericons" />
                <p className="m-0 ms-2">Mon.-Fri: 10:00am to 6:00pm</p>
                </div>
                
              </div>
      </div>
       
        </div>
      </div>
      <div className="footer1 text-center mt-5 p-2  lastfooter">
          <p className="m-0">Copyright © 2024 Prayosha Infotech. All Rights Reserved.</p>
        </div>
    </footer>
  );
};

export default Footer;
