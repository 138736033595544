import { Container, Row, Col, Button } from "react-bootstrap";
import classNames from "classnames"; 
import "./portfolio.css";




const portfolioList = [
	{
		image: "../images/logo_design.jpg",
		title: "Tours & Travels Logo",
		categories: ["Logo Design"],
	},
	{
		image: "../images/banner_design.jpg",
		title: "Restaurant Banner",
		categories: ["Banner Design"],
	},
	{
		image: "../images/Digital_Ads.jpg",
		title: "Google Ads",
		categories: ["Digital Ads"],
	},
	{
		image: "../images/story.jpg",
		title: "Instgram Story",
		categories: ["Social Media Graphics"],
	},
	{
		image: "../images/smp.jpg",
		title: "Social media post",
		categories: ["Social Media Graphics"],
	},
	{
		image: "../images/branding.jpg",
		title: "Ecommerce Store Logo",
		categories: ["Logo Design"],
	},
	{
		image: "../images/menu.jpg",
		title: "Restaurant Menu",
		categories: ["Menu Designing"],
	},
	{
		image: "../images/packing.jpg",
		title: "Packaging",
		categories: ["Packaging Design"],
	},
	// {
	// 	image: "https://cdn.easyfrontend.com/pictures/portfolio/portfolio5.jpg",
	// 	title: "Designing",
	// 	categories: ["Product Design"],
	// },
];

const  Portfolio1_YGJeLYBA  = () => {
	return (
		<section className="ezy__portfolio1_YGJeLYBA">
			<Container>
				
				<Row>
					{portfolioList.map((portfolio, i) => (
						<Col md={6} lg={4} key={i}>
							<div className="ezy__portfolio1_YGJeLYBA-item position-relative mt-4">
								<img
									src={portfolio.image}
									alt={portfolio.alt}
									className="img-fluid w-100"
								/>
								<div className="ezy__portfolio1_YGJeLYBA-content text-center p-4">
									<h5>{portfolio.title}</h5>
									<p className="mb-0">{portfolio.categories.join(", ")}</p>
								</div>
							</div>
						</Col>
					))}
				</Row>
			</Container>
		</section>
	);
};

export default Portfolio1_YGJeLYBA;