import React from "react";
import InnerSlider from "../components/InnerSlide"
import UIUX_Img from "../Assets/uiux_img.jpg";
import yesIcon from '../Assets/yes_icon.svg';
import dm_new_sec from "../Assets/ui_new_ser.jpg";
import Uiux_portfolio from "../components/portfolio/Uiux";


const Uiux = () => {
  return <div>
    <InnerSlider slider="UI-UX Designing" />

    <div className="graphics_about py-5 container">
        <div className="row flex align-items-center row-gap-5">
         
          <div className="col-md-6">
          <h2 className='title-sec mb-3' data-aos="fade-right" data-aos-duration="800">User-First Design for Unforgettable Experiences.</h2>
              <p data-aos="fade-right" data-aos-duration="1000">Unlock the potential of user-centric design with Prayosha Infotech's UI/UX design services. In today's digital landscape, user experience (UX) and user interface (UI) design are critical elements that can make or break your digital products and services. Our team of expert designers is here to ensure your users have a seamless and delightful experience that keeps them coming back for more.</p>
          </div>
          <div className="col-md-6">
          <div className="gsa_img text-center">
            <img src={UIUX_Img} alt="graphics design services" width={350} data-aos="fade-left" data-aos-duration="1000"  />
          </div>
          </div>
        </div>
      </div>
  

      <div className="dm_new_sec py-5 ">
        <div className="container">
          <div className="row d-flex align-items-center">
          <div className="col-md-6">
            <img className="img-fluid" src={dm_new_sec} alt="" data-aos="fade-up-right" data-aos-duration="1000" />
            </div>
            <div className="col-md-6" data-aos="fade-up-left" data-aos-duration="1000">
            <h2 className='title-sec mb-3 text-left text-white '>Our UI/UX Design Services</h2>
            <ol>
              <li className="new_inner_sec_li">User Research:</li>
              <p className="text-white">Gain insights into your users' behavior and preferences through comprehensive research and analysis.</p>
              <li className="new_inner_sec_li">Information Architecture:</li>
              <p className="text-white">Create a logical and organized structure for your digital products to enhance user navigation.</p>
              <li className="new_inner_sec_li">Wireframing and Prototyping:</li>
              <p className="text-white">Visualize and test the layout and functionality of your interfaces before development begins.</p>
              <li className="new_inner_sec_li">User Interface (UI) Design:</li>
              <p className="text-white">Craft visually appealing and responsive UI designs that elevate your brand.</p>
              <li className="new_inner_sec_li">User Experience (UX) Design:</li>
              <p className="text-white">Ensure a smooth and enjoyable user journey through intuitive interactions and user flows..</p>
            </ol>
            </div>
          
          </div>
        </div>
            
      </div>


      <div className="whygraphics container py-5" data-aos="fade-up" data-aos-duration="1000">
      <h2 className='title-sec mb-3 text-center'>Why Choose Our UI/UX Design Services</h2>

          <div className="row py-5 d-flex row-gap-4">
            <div className="col-md-6 d-grid gap-3">
              <div className="d-flex align-items-center gap-3">
                <div className="yesimg">
                  <img src={yesIcon} alt="yes icon" width={30} />
                </div>
                <h4>User-Centered Approach</h4>
              </div>
              <p>We place your users at the center of everything we do. Our design process begins with in-depth research and user testing to understand their needs, preferences, and pain points.</p>
            </div>
            <div className="col-md-6 d-grid gap-3">
              <div className="d-flex align-items-center gap-3">
                <div className="yesimg">
                  <img src={yesIcon} alt="yes icon" width={30} />
                </div>
                <h4>Beautiful and Functional Designs</h4>
              </div>
              <p>We believe that great design not only looks good but also functions flawlessly. Our designs combine aesthetics with usability to create intuitive interfaces that engage and delight users.</p>
            </div>
            <div className="col-md-6 d-grid gap-3">
              <div className="d-flex align-items-center gap-3">
                <div className="yesimg">
                  <img src={yesIcon} alt="yes icon" width={30} />
                </div>
                <h4>Custom Solutions</h4>
              </div>
              <p>No two projects are alike. We tailor our UI/UX design services to your specific business goals, ensuring that the end product aligns with your unique brand identity and objectives.</p>
            </div>
            <div className="col-md-6 d-grid gap-3">
              <div className="d-flex align-items-center gap-3">
                <div className="yesimg">
                  <img src={yesIcon} alt="yes icon" width={30} />
                </div>
                <h4>Iterative Design</h4>
              </div>
              <p>We follow an iterative design approach, continuously refining and improving the design based on feedback and testing to ensure optimal results.</p>
            </div>
          </div>
        
      </div>



      <div className="graphics_work py-5" data-aos="fade-up" data-aos-duration="1000">
      <h2 className='title-sec  text-center'>Our UI/UX Work</h2>
     
      <Uiux_portfolio />


      </div>




  </div>;
};

export default Uiux;
