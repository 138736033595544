import { Container, Row, Col, Button } from "react-bootstrap";
import classNames from "classnames"; 
import "./portfolio.css";




const portfolioList = [
	{
		image: "../images/app.jpg",
		title: "Software Solution App",
		categories: ["Application Design"],
	},
	{
		image: "../images/dashboard.jpg",
		title: "Investment Website Dashboard",
		categories: ["Dashboard Design"],
	},
	{
		image: "../images/nice.jpg",
		title: "Institute Website",
		categories: ["Website Design"],
	},
	{
		image: "../images/opt.jpg", 
		title: "Entertainment Platform",
		categories: ["Website Design"],
	},
	{
		image: "../images/trade.jpg",
		title: "Investment Website",
		categories: ["Website Design"],
	},
	// {
	// 	image: "https://cdn.easyfrontend.com/pictures/portfolio/portfolio_1_6.png",
	// 	title: "Management",
	// 	categories: ["Project Manager"],
	// },
	// {
	// 	image: "https://cdn.easyfrontend.com/pictures/portfolio/portfolio_1_7.png",
	// 	title: "Logo Design",
	// 	categories: ["Branding"],
	// },
	// {
	// 	image: "https://cdn.easyfrontend.com/pictures/portfolio/portfolio1.jpg",
	// 	title: "Full Stack Dev",
	// 	categories: ["Wordpress"],
	// },
	// {
	// 	image: "https://cdn.easyfrontend.com/pictures/portfolio/portfolio5.jpg",
	// 	title: "Designing",
	// 	categories: ["Product Design"],
	// },
];

const  Uiux_portfolio  = () => {
	return (
		<section className="ezy__portfolio1_YGJeLYBA">
			<Container>
				
				<Row>
					{portfolioList.map((portfolio, i) => (
						<Col md={6} lg={4} key={i}>
							<div className="ezy__portfolio1_YGJeLYBA-item position-relative mt-4">
								<img
									src={portfolio.image}
									alt={portfolio.alt}
									className="img-fluid w-100"
								/>
								<div className="ezy__portfolio1_YGJeLYBA-content text-center p-4">
									<h5>{portfolio.title}</h5>
									<p className="mb-0">{portfolio.categories.join(", ")}</p>
								</div>
							</div>
						</Col>
					))}
				</Row>
			</Container>
		</section>
	);
};

export default Uiux_portfolio;