import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Logo from "../Assets/logo_2.png";
import "./header.css";
import { Link, NavLink } from "react-router-dom";
import React, { useState } from "react";
import { RiMenu3Line, RiCloseLine } from "react-icons/ri";
import { RiMailFill, RiPhoneFill } from "react-icons/ri";
import Rfqmodel from '../components/Rfqmodel';



function Header(props) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const [modal, setModal] = useState(false);

  return (
    <>
   
      <div className="header">
        <Container>
          <Row>
            <div className="col-md-6 d-flex align-items-center h-sec1 ">
             <a href="mailto: info@prayoshainfotech.in"> <RiMailFill className="header-icons"/></a>
              <p className="text-h">info@prayoshainfotech.in</p>
            </div>

            <div className="col-md-6 align-items-center d-flex h-sec2">
              <a href="tel:+919909995536"><RiPhoneFill className="header-icons" /></a>
              <p className="text-h">+91 99099 95536</p>
            </div>
          </Row>
        </Container>
      </div>
      <Navbar expand="lg" className="bg-body-white">
        <Container>
          <NavLink to="/">
            <img src={Logo} alt="" className="img-fluid" width={180} />
          </NavLink>
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={toggleMenu}>
            {isOpen ? <RiCloseLine /> : <RiMenu3Line />}
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <NavLink  to="/" className="nav-link">
                Home
              </NavLink>
              {/* <NavLink to="/Whatwedo" className="nav-link">What We Do</NavLink> */}
              {/* <div class="dropdown" >
                <button class="dropbtn dropdown-toggle nav-link">What We Do</button>
                <div class="dropdown-content">
                <NavLink to="/WhatWeDo/GraphicsDesign">
                  Graphics Design
                </NavLink>
                <NavLink to="/WhatWeDo/UiuxDesign">UI & UX Design</NavLink>
                <NavLink to="/WhatWeDo/WebDevelopment">Web Development</NavLink>
                <NavLink to="/WhatWeDo/AppDevelopment">App Development</NavLink>
                <NavLink to="/WhatWeDo/DigitalMarketing">
                  Digital Marketing
                </NavLink>
                </div>
              </div> */}
              <NavDropdown title="What We Do" >
                <NavLink to="/WhatWeDo/GraphicsDesign">
                  Graphics Design
                </NavLink>
                <NavLink to="/WhatWeDo/UiuxDesign">UI & UX Design</NavLink>
                <NavLink to="/WhatWeDo/WebDevelopment">Web Development</NavLink>
                <NavLink to="/WhatWeDo/AppDevelopment">App Development</NavLink>
                <NavLink to="/WhatWeDo/DigitalMarketing">
                  Digital Marketing
                </NavLink>
              </NavDropdown>
              <NavLink to="/AboutUs" className="nav-link">
                About Us
              </NavLink>
              <NavLink to="/Porfolio" className="nav-link">
                Portfolio
              </NavLink>
              <NavLink to="/ContactUs" className="nav-link">
                Contact Us
              </NavLink>
              {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown>  */}
              <Button className="ms-4" onClick={()=> setModal(true)}>Request A Quote</Button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Rfqmodel modal={modal} setModal={setModal} />
      
    </>
  );
}

export default Header;
