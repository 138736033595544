import React from "react";
import InnerSlider from "../components/InnerSlide"
import Web_Img from "../Assets/dmsec.jpg";
import dm_new_sec from "../Assets/dm_new_sec.jpg";
import yesIcon from '../Assets/yes_icon.svg';


const DM = () => {
  return <div>

    <InnerSlider slider="Digital Marketing" />

    <div className="graphics_about py-5 container">
        <div className="row flex align-items-center row-gap-5">
        <div className="col-md-6">
          <div className="gsa_img text-center">
            <img src={Web_Img} alt="graphics design services" width={350} data-aos="fade-right" data-aos-duration="1000"/>
          </div>
          </div>
          <div className="col-md-6">
          <h2 className='title-sec mb-3'data-aos="fade-left" data-aos-duration="800">Boosting Your Digital Impact</h2>
              <p data-aos="fade-left" data-aos-duration="1000">Welcome to Prayosha Infotech, your trusted partner for comprehensive digital marketing solutions. In today's hyper-connected world, a strong online presence is essential for success. Our team of digital marketing experts is here to help you navigate the digital landscape, reach your target audience, and achieve your business goals through effective online strategies.</p>
          </div>
          
        </div>
      </div>
  

      <div className="dm_new_sec py-5 d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-md-6" data-aos="fade-right" data-aos-duration="1000">
            <h2 className='title-sec mb-3 text-left text-white '>Our Digital Marketing Services</h2>
            <ol>
              <li className="new_inner_sec_li">Search Engine Optimization (SEO):</li>
              <p className="text-white">Improve your website's visibility on search engines, attract organic traffic, and increase your rankings for relevant keywords.</p>
              <li className="new_inner_sec_li">Pay-Per-Click (PPC) Advertising:</li>
              <p className="text-white">Launch targeted ad campaigns that drive traffic, increase conversions, and maximize your return on investment (ROI).</p>
              <li className="new_inner_sec_li">Social Media Marketing:</li>
              <p className="text-white">Leverage the power of social media to engage with your audience, build brand awareness, and drive customer loyalty.</p>
              <li className="new_inner_sec_li">Analytics and Reporting:</li>
              <p className="text-white">Build and nurture your customer relationships through personalized email campaigns that drive conversions and retention.</p>
              
            </ol>
            </div>
            <div className="col-md-6" data-aos="fade-left" data-aos-duration="1000">
            <img className="img-fluid" src={dm_new_sec} alt="" />
            </div>
          </div>
        </div>
            
      </div>



      <div className="whygraphics container py-5" data-aos="fade-up" data-aos-duration="1000">
      <h2 className='title-sec mb-3 text-center'>Why Choose Our Digital Marketing Services</h2>

          <div className="row py-5 d-flex row-gap-4">
            <div className="col-md-6 d-grid gap-3">
              <div className="d-flex align-items-center gap-3">
                <div className="yesimg">
                  <img src={yesIcon} alt="yes icon" width={30} />
                </div>
                <h4>Strategic Approach</h4>
              </div>
              <p>We take a strategic approach to digital marketing, tailoring our services to your unique needs and objectives.</p>
            </div>
            <div className="col-md-6 d-grid gap-3">
              <div className="d-flex align-items-center gap-3">
                <div className="yesimg">
                  <img src={yesIcon} alt="yes icon" width={30} />
                </div>
                <h4>Data-Driven Insights</h4>
              </div>
              <p>Our decisions are based on data and analytics, ensuring that every marketing dollar is spent efficiently to deliver the best results.</p>
            </div>
            <div className="col-md-6 d-grid gap-3">
              <div className="d-flex align-items-center gap-3">
                <div className="yesimg">
                  <img src={yesIcon} alt="yes icon" width={30} />
                </div>
                <h4>Multichannel Expertise</h4>
              </div>
              <p>From search engine optimization (SEO) and pay-per-click advertising to social media marketing and content marketing, we excel in all facets of digital marketing.</p>
            </div>
            <div className="col-md-6 d-grid gap-3">
              <div className="d-flex align-items-center gap-3">
                <div className="yesimg">
                  <img src={yesIcon} alt="yes icon" width={30} />
                </div>
                <h4>Proven Results</h4>
              </div>
              <p>Our track record of success speaks for itself. We've helped businesses of all sizes and industries grow their online presence and boost their revenue.</p>
            </div>
          </div>
        
      </div>

      



  </div>;
};

export default DM;
