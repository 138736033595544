import React from "react";
import InnerSlider from '../components/InnerSlide';
import UIUX_Img from "../Assets/appsec.jpg";
import yesIcon from '../Assets/yes_icon.svg';
import dm_new_sec from "../Assets/app_new_ser.jpg";

const Appd = () => {
  return <div>

    <InnerSlider slider="App Development" />

    <div className="graphics_about py-5 container">
        <div className="row flex align-items-center row-gap-5">
         
          <div className="col-md-6">
          <h2 className='title-sec mb-3'  data-aos="fade-right" data-aos-duration="800">Building Tomorrow’s Apps, Today</h2>
              <p data-aos="fade-right" data-aos-duration="1000">Welcome to Prayosha Infotech, your partner for cutting-edge app development services. In today's mobile-first world, a well-crafted mobile application can be the key to reaching and engaging your audience effectively. Our team of experienced app developers is here to turn your app idea into a reality, creating user-friendly and innovative solutions that cater to your specific business needs.</p>
          </div>
          <div className="col-md-6">
          <div className="gsa_img text-center">
            <img src={UIUX_Img} alt="graphics design services" width={350} data-aos="fade-left" data-aos-duration="1000"/>
          </div>
          </div>
        </div>
      </div>

      <div className="dm_new_sec py-5 ">
        <div className="container">
          <div className="row d-flex align-items-center">
          <div className="col-md-6">
            <img className="img-fluid" src={dm_new_sec} alt=""  data-aos="fade-up-right" data-aos-duration="1000" />
            </div>
            <div className="col-md-6" data-aos="fade-up-left" data-aos-duration="1000">
            <h2 className='title-sec mb-3 text-left text-white ' >Our App Development  Services</h2>
            <ol>
              <li className="new_inner_sec_li">Mobile App Development:</li>
              <p className="text-white">We develop native and cross-platform mobile apps, ensuring a seamless user experience on both iOS and Android devices.</p>
              <li className="new_inner_sec_li">Custom App Solutions</li>
              <p className="text-white">Whether you need a business app, a social networking app, an e-commerce app, or any other custom solution, we have the expertise to bring your vision to life.</p>
              <li className="new_inner_sec_li">App Testing and Quality Assurance:</li>
              <p className="text-white">Rigorous testing ensures that your app functions flawlessly and is free of bugs before launch.</p>
              <li className="new_inner_sec_li">App Maintenance and Updates:</li>
              <p className="text-white">We offer ongoing maintenance and support to keep your app up-to-date and secure.</p>
            
            </ol>
            </div>
          
          </div>
        </div>
            
      </div>
  
      <div className="whygraphics container py-5" data-aos="fade-up" data-aos-duration="800">
      <h2 className='title-sec mb-3 text-center'>Why Choose Our App Development  Services</h2>

          <div className="row py-5 d-flex row-gap-4">
            <div className="col-md-6 d-grid gap-3">
              <div className="d-flex align-items-center gap-3">
                <div className="yesimg">
                  <img src={yesIcon} alt="yes icon" width={30} />
                </div>
                <h4>Custom Solutions</h4>
              </div>
              <p>We understand that each business is unique, and so are its app requirements. Our app development solutions are tailored to your specific needs, ensuring a seamless integration of your brand identity and goals.</p>
            </div>
            <div className="col-md-6 d-grid gap-3">
              <div className="d-flex align-items-center gap-3">
                <div className="yesimg">
                  <img src={yesIcon} alt="yes icon" width={30} />
                </div>
                <h4>Expertise Across Platforms</h4>
              </div>
              <p>Whether you need an iOS app, Android app, or a cross-platform solution, our developers have the expertise to create apps that run flawlessly on all major platforms.</p>
            </div>
            <div className="col-md-6 d-grid gap-3">
              <div className="d-flex align-items-center gap-3">
                <div className="yesimg">
                  <img src={yesIcon} alt="yes icon" width={30} />
                </div>
                <h4>User-Centric Design</h4>
              </div>
              <p> We prioritize user experience (UX) and interface (UI) design, ensuring that your app is not only visually appealing but also highly intuitive and user-friendly.</p>
            </div>
            <div className="col-md-6 d-grid gap-3">
              <div className="d-flex align-items-center gap-3">
                <div className="yesimg">
                  <img src={yesIcon} alt="yes icon" width={30} />
                </div>
                <h4>Cutting-Edge Technology</h4>
              </div>
              <p>We stay up-to-date with the latest technologies and trends, enabling us to build apps that are feature-rich and technologically advanced.</p>
            </div>
          </div>
        
      </div>


  </div>;
};

export default Appd;
