import React from 'react';
import img from "../Assets/faq img.png";
import Line from "../Assets/Line 9.svg";
import Accordion from 'react-bootstrap/Accordion';

const FAQ = () => {
  return (
    <div>
      <div className="container my-5">
        <div className="row ">
          <div className="col-md-5">
            <img data-aos="zoom-in-up" data-aos-duration='800' src={img} alt="" className='img-fluid' />
          </div>
          <div data-aos="zoom-in-up"data-aos-duration='800'  className="col-md-7">
            <div className="line d-flex align-items-center mb-3">
              <img src={Line} alt="" className='me-2 lineimg' /><h5 className='sec-titleb'>FAQ</h5>

            </div>
            <h2 className='title-sec mb-3'>Frequently Asked<br />
              Questions</h2>

            {/* faq start */}
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>What digital marketing services do you offer? </Accordion.Header>
                <Accordion.Body>
                We offer a variety of digital marketing services, including search engine optimization (SEO), pay-per-click (PPC) advertising, social media marketing, email marketing, and content marketing. Our goal is to help your business increase its online visibility and drive more traffic and leads to your website or app.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>How can you help me improve my website's user experience? </Accordion.Header>
                <Accordion.Body>
                Our UI/UX design team can conduct a thorough analysis of your website or app's user experience and create a customized strategy to improve its usability, accessibility, and engagement. By improving the user experience, we can help you increase user satisfaction, reduce bounce rates, and ultimately drive more conversions.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>How do you ensure the security and reliability of my website or app? </Accordion.Header>
                <Accordion.Body>
                We use industry-leading security protocols and best practices to ensure the security and reliability of your website or app. We also provide ongoing maintenance and support to ensure that your site is always up-to-date and secure.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>How can you help me increase my online sales and revenue?  </Accordion.Header>
                <Accordion.Body>
                Our e-commerce development team can create a custom online store or integrate e-commerce functionality into your existing website or app. We can also help you optimize your product listings, checkout process, and marketing strategy to drive more sales and revenue.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            {/* faq end */}

          </div>
        </div>
      </div>
    </div>
  )
}

export default FAQ